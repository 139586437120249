import './App.css';
import React, {Component} from 'react';
import {Stage, Layer, Rect, Circle, Text, Line, Image} from 'react-konva';
import useImage from 'use-image';

const screenSize =  16/12.25;
var width = 0;
var height = 0;
if ((window.innerWidth / screenSize) > window.innerHeight) {
    width = window.innerHeight * screenSize;
    height = window.innerHeight;
} else {
    width = window.innerWidth;
    height = window.innerWidth / screenSize;
}




const proportion = width / 1600;

const titleHeight = 50;
const titleHoursHeight = 15;
const legendHeight = 50;

const roomWidth = 200;
var query = window.location.search.substring(1).split('/');
var campus = query[0];

var goOn = false;
var date = new Date(query[1]);
if (isNaN(date.getTime())) {
    date = new Date();
    goOn = true;
}

if ((campus !== 've') && (campus !== 'vr')) {
    campus = 've';
}


console.log(campus, date);

var url = "https://ahwmd2o78l.execute-api.eu-central-1.amazonaws.com/dev";

// const url = "http://localhost:8000";

var timeFrom = 9;
var timeTo = 18;


var roomsPosition = [];
var rectHeight = 0;

var heightForRooms = 1200 - titleHeight - titleHoursHeight - legendHeight;

function App() {

    return (
        <Page/>
    );
}

export default App;


class Page extends Component {

    constructor(props) {
        super(props);
        // var date = new Date(2019, 9, 31);
        //var date = new Date();

        this.state = {
            rooms: [],
            events: [],
            categories: [],
            date: date
        };
        this.loadData(this.state.date);

    }


    componentDidMount() {
        this.clockTimer = setInterval(
            () =>
                this.refreshClock(this.state),


            1000
        );
        this.timerID = setInterval(
            () => this.loadData(this.state.date),
            30000
        );


    }

    refreshClock(state) {
        if (goOn) {
            date = new Date();
            this.setState({date: date});
            //   console.log(this.state);
        }
    }

    async loadData(date) {
        fetch(url + '/get-events-with-rooms-and-categories/' + campus + '/' + isoDate(date) + '?onlyBusy=true')
            .then(res => res.json())
            .then((data) => {
                calculateAndSetExtremesHours(data.events);
                this.setState(data)
                if (goOn) {
                    date = new Date();
                    this.setState({date: date});
                    //   console.log(this.state);
                }
            });
    }

    render() {
        return renderAll(this.state);
    }
}

function renderAll(state) {
    return (
        <Stage width={window.innerWidth} height={window.innerHeight}>
            <Layer>
                <BackgroundImage/>
                <AreaRects/>
                <Giorno date={state.date}/>
                <Clock date={new Date()}/>
                <ElencoAule rooms={state.rooms}/>
                <ElencoCategorie categories={state.categories}/>
                <Timeline date={new Date()}/>
                <Events events={state.events} categories={state.categories} rooms={state.rooms} key={'events'}/>
            </Layer>
        </Stage>
    );
}

const LogoImage = () => {
    const [image] = useImage('logo.png');
    return <Image image={image} height={(titleHeight - 4) * proportion} x={36} y={2}/>;
};

const BackgroundImage = () => {
    const [image] = useImage('background.png');
    return <Image image={image} height={height} width={width}/>;
};

function AreaRects(props) {
    return (
        <Rect x={0} y={0} width={width} height={titleHeight * proportion} fill="#cc0033"/>
    );
}


function Clock(props) {
    var date = props.date;

    var h = date.getHours(); // 0 - 23
    var m = date.getMinutes(); // 0 - 59
    var s = date.getSeconds(); // 0 - 59
    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;
    s = (s < 10) ? "0" + s : s;
    var timeForClock = h + ':' + m + ':' + s;

    return <Text text={timeForClock} fontSize={35 * proportion} y={0} x={1450 * proportion} align="left"
                 verticalAlign="middle"
                 width={width}
                 height={titleHeight * proportion} fill="white" fontFamily="Raleway" fontStyle="bold"/>;
}

function Giorno(props) {
    const items = [];
    var date = props.date;
    var day = date.toLocaleDateString('IT-it', {day: '2-digit'});
    var weekday = date.toLocaleDateString('IT-it', {weekday: 'long'});
    var month = date.toLocaleDateString('IT-it', {month: 'long'});
    var year = date.toLocaleDateString('IT-it', {year: 'numeric'});
    var text = weekday[0].toUpperCase() + weekday.slice(1) + ' ' + day + ' ' + month + " " + year;
    items.push(<Text text={text} fontSize={35 * proportion} y={0} x={36} align="left"
                     verticalAlign="middle"
                     width={width}
                     height={titleHeight * proportion} fill="white" fontFamily="Raleway" fontStyle="bold"/>);

    return items;
}

function ElencoAule(props) {
    var rooms = props.rooms;
    var fontSize = 300 / Math.max(10, (Object.keys(rooms)).length);


    rectHeight = Math.floor(heightForRooms / Math.max(10, (Object.keys(rooms)).length));
    const items = []
    var positionList = [];
    var position = 0;
    var i = 0;
    for (let index of Object.keys(rooms)) {
        position = (titleHeight + titleHoursHeight + i * rectHeight) * proportion;
        positionList.push({id: index, position: position});
        items.push(<Text text={index} fontSize={fontSize * proportion} x={36} y={position} verticalAlign="middle"
                         height={rectHeight * proportion} fontStyle="bold" key={"room-" + index} fill="white"/>);
        items.push(<Line
            x={0}
            y={position}
            points={[0, 0, 1600 * proportion, 0]}
            stroke="gray" dash={[1, 3]}
            key={"line-" + index}/>);
        i++;
    }
    roomsPosition = positionList;
    return items;
}


function ElencoCategorie(props) {
    var categories = props.categories;
    const items = []
    var rectWidth = (width - 36) / (Object.keys(categories)).length;
    var i = 0;
    for (let index of Object.keys(categories)) {
        var value = categories[index];

        items.push(<Rect
            y={height - legendHeight * proportion} x={36 + i * rectWidth}
            width={rectWidth - 10 * proportion} height={legendHeight * proportion}
            fill={value.bgcolor}
            key={"rect-" + value.id}
        />)
        items.push(<Text text={value.id} fontSize={35 * proportion}
                         y={height - legendHeight * proportion} x={36 + i * rectWidth}
                         width={rectWidth - 10 * proportion} height={legendHeight * proportion}
                         align="center" verticalAlign="middle"
                         fill={value.color} key={"text-" + value.id} fontFamily="Raleway" fontStyle="bold"/>)
        i++;
    }
    return items;
}

function Timeline(props) {
    const items = [];
    var N = props.date;
    //N.setHours(16,0,0,0);
    var now = N.getTime();
    var startingDate = new Date();
    startingDate.setHours(timeFrom, 0, 0, 0);
    var endingDate = new Date();
    endingDate.setHours(timeTo, 0, 0, 0);

    var min = startingDate.getTime();
    var max = endingDate.getTime();

    var x = 0;
    var K = new Date();

    for (var i = timeFrom; i <= timeTo; i++) {
        K.setHours(i, 0, 0, 0);
        x = Math.floor(roomWidth + (K.getTime() - min) / (max - min) * (1600 - roomWidth));

        items.push(<Text text={i} fontSize={16 * proportion} y={titleHeight * proportion} x={x * proportion - 20}
                         align="center" verticalAlign="middle" width={40} fontStyle="bold" key={"text-" + i}
                         fill="white"/>);

        items.push(
            <Line
                x={x * proportion}
                y={(titleHeight + titleHoursHeight) * proportion}
                points={[0, 0, 0, (900 - legendHeight - titleHeight - titleHoursHeight) * proportion]}
                stroke="gray" dash={[1, 3]}
                key={"line-" + i}/>
        );
    }

    x = Math.floor(roomWidth + (now - min) / (max - min) * (1600 - roomWidth));

    if (x >= roomWidth && x <= 1600) {
        items.push(
            <Line
                strokeWidth={5}
                x={x * proportion}
                y={(titleHeight + titleHoursHeight) * proportion}
                points={[0, 0, 0, heightForRooms * proportion]}
                stroke="red" dash={[10, 2, 2, 2]} key="red-line"
            />
        );
    }
    return items;
}

function Events(props) {
    // console.log(props.events)
    return props.events.map((event) => <Event event={event} categories={props.categories} rooms={props.rooms}
                                              key={event.id}/>);
}

function Event(props) {
    var event = props.event;


    const items = [];

    if (event.room == null) {
        return items;
    }
    var rooms = props.rooms;
    var fontSize = 300 / Math.max(10, (Object.keys(rooms)).length);

    var color = event.category.color;
    var bgColor = event.category.bgcolor;
    var y = getYpositionFromEvent(event, 1);

    var k = (1600 - roomWidth) / (timeTo - timeFrom) / 100;

    var x = roomWidth + (event.from - timeFrom * 100) * k;
    var w = (event.to - event.from) * k;
    var text = event.text;
    var radius = ((rectHeight - 4) / 2) * proportion;
    if (event.subtext.length > 0) {
        text = text + ' - ' + event.subtext;
    }
    items.push(<Rect
        y={y + 3} x={x * proportion}
        width={w * proportion} height={(rectHeight - 3) * proportion}
        fill={bgColor}
    />)
    items.push(<Text
        text={text.trim()}
        y={y + 4} x={x * proportion+radius*1.5} fontSize={fontSize * proportion}
        width={w * proportion-radius*1.5} height={(rectHeight - 3) * proportion}
        fill={color} fontStyle="bold"
        verticalAlign="middle" align="left"
    />)
    if (event.label) {
        items.push(circle(x, y, radius, bgColor, color));
        items.push(circleText(x, y, radius, event.label.substr(0, 1), color, fontSize));
    }
    if (event.roomExtra1) {
        y = getYpositionFromEvent(event, 2);
        items.push(<Rect
            y={y + 3} x={x * proportion}
            width={w * proportion} height={(rectHeight - 3) * proportion}
            fill={bgColor}
        />)
        items.push(<Text
            text={text.trim()}
            y={y + 4} x={x * proportion+radius*1.5} fontSize={fontSize * proportion}
            width={w * proportion-radius*1.5} height={(rectHeight - 3) * proportion}
            fill={color} fontStyle="bold"
            verticalAlign="middle" align="left"
        />)
        if (event.label) {
            items.push(circle(x, y, radius, bgColor, color));
            items.push(circleText(x, y, radius, event.label.substr(0, 1), color, fontSize));
        }
    }
    if (event.roomExtra2) {
        y = getYpositionFromEvent(event, 3);
        items.push(<Rect
            y={y} x={x * proportion}
            width={w * proportion} height={rectHeight - 3}
            fill={bgColor}
        />)
        items.push(<Text
            text={text.trim()}
            y={y} x={x * proportion+radius*1.5} fontSize={fontSize * proportion}
            width={w * proportion-radius*1.5} height={rectHeight}
            fill={color}
            verticalAlign="middle" align="left"
        />)
        if (event.label) {
            items.push(circle(x, y, radius, bgColor, color));
            items.push(circleText(x, y, radius, event.label.substr(0, 1), color, fontSize));
        }
    }




    return items;
}

function circle(x, y, radius, bgColor, fgColor) {
    return (<Circle
        y={y + radius + 4} x={x * proportion}
        radius={radius}
        fill={bgColor}
        stroke={fgColor}
    />)
}

function circleText(x, y, radius, text, fgColor, fontSize) {
    var f = fontSize * .8;
    return (<Text
        text={text}
        y={y + 4} x={x * proportion - radius} fontSize={f}
        width={radius * 2} height={radius * 2}
        fill={fgColor} fontStyle="bold"
        verticalAlign="middle" align="center"
    />)
}

function isoDate(date) {
    return date.toLocaleDateString([], {year: 'numeric'}) + date.toLocaleDateString([], {month: '2-digit'}) + date.toLocaleDateString([], {day: '2-digit'});
}


function getYpositionFromEvent(event, roomCount) {
    var position = 0;
    roomsPosition.forEach(function (room) {
        if (room.id && roomCount === 1 && room.id.trim() === event.room.id.trim()) {
            position = room.position;
        }
        if (room.id && roomCount === 2 && room.id.trim() === event.roomExtra1.id.trim()) {
            position = room.position;
        }
        if (room.id && roomCount === 3 && room.id.trim() === event.roomExtra2.id.trim()) {
            position = room.position;
        }
    });

    return Math.floor(position);
}


function calculateAndSetExtremesHours(events) {
    var min = 2400;
    var max = 0;
    events.forEach(function (event) {
        min = Math.min(parseInt(event.from), min);
        max = Math.max(parseInt(event.to), max);
    });
    timeFrom = Math.floor(min / 100);
    timeTo = Math.ceil(max / 100);
}
